import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { Suspense, lazy } from 'preact/compat';
const SelectGroup = lazy(
    () => import(/* webpackChunkName: "select-group" */ '../../../shared/form/select-group')
);
import _ from 'underscore';

class Component extends BaseComponent {
    shouldComponentUpdate() {
        return false;
    }

    render({ decks, category, label, info }) {
        if (decks && decks.length) {
            let options = {};
            decks.map(deck => options[deck.slug] = deck.name);

            return (
                <tarot-deck-selector category={category} label={label} info={info}>
                    <Suspense fallback={<div className='skeleton' />}>
                        <SelectGroup
                            options={options}
                            default='Search All Tarot Decks'
                            onChange={this.handleChange}
                        />
                    </Suspense>
                </tarot-deck-selector>
            );
        }

        return null;
    }

    handleChange = (e) => {
        if (this.props.category && this.props.label) {
            this.props.trackEvent([
                'Click',
                `${this.props.info}_${e.target.value}`,
            ]);
        }

        document.location.href = `/tarot/decks/${e.target.value}`;
    }
}

const mapStateToProps = (state) => ({
    decks: state.decks,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
