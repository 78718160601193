import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { Suspense, lazy } from 'preact/compat';
const SelectGroup = lazy(
    () => import(/* webpackChunkName: "select-group" */ '../../../shared/form/select-group')
);
import _ from 'underscore';

class Component extends BaseComponent {
    shouldComponentUpdate() {
        return false;
    }

    render({ cards, placeholder, category, label, info }) {
        if (cards && cards.length) {
            let options = {};
            cards.map(card => options[card.canonical_rank] = card.name);

            return (
                <tarot-card-selector category={category} label={label} info={info}>
                    <Suspense fallback={<div className='skeleton' />}>
                        <SelectGroup
                            options={options}
                            default={placeholder || 'Search Any Tarot Card Meaning'}
                            onChange={this.handleChange}
                        />
                    </Suspense>
                </tarot-card-selector>
            );
        }

        return (
            <tarot-card-selector>
                <select-group>
                    <select>
                        <option disabled value>
                            {placeholder || 'Search Any Tarot Card Meaning'}
                        </option>
                    </select>
                </select-group>
            </tarot-card-selector>
        );
    }

    handleChange = (e) => {
        if (this.props.category && this.props.label) {
            this.props.trackEvent([
                'Click',
                `${this.props.info}_${e.target.value}`,
            ]);
        }

        document.location.href = `/tarot/cards/${e.target.value}`;
    }
}

const mapStateToProps = (state) => ({
    cards: state.deck && state.deck.cards,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
