import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import _ from 'underscore';

class Component extends BaseComponent {
    constructor() {
        super();

        this.decks = document.querySelectorAll('.tarot-deck-list column[data-themes]');

        this.state = {
            themes: {
                Unique: false,
                Beautiful: false,
                Beginner: false,
                Modern: false,
                Vintage: false,
                Animal: false,
                Classic: false,
                Love: false,
                Inclusive: false,
                Feminine: false,
                Fantasy: false,
                Popular: false,
            },
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState != this.state) {
            return true;
        }

        return false;
    }

    render({ category }, { themes }) {
        return (
            <tarot-deck-filters category={category}>
                <row className='is-wrap is-justified-center has-margin-30-bottom'>
                    {Object.keys(themes).map(theme => (
                        <column className='is-3 is-4-mobile has-padding-10-bottom has-padding-10-right-mobile'>
                            <label className='checkbox'>
                                <input
                                    type='checkbox'
                                    checked={themes[theme]}
                                    onChange={() => this.filterDecks(theme)}
                                />
                                <span />
                                <p>{theme.charAt(0).toUpperCase()}{theme.slice(1)} Tarot Decks</p>
                            </label>
                        </column>
                    ))}
                </row>
            </tarot-deck-filters>
        );
    }

    filterDecks = (theme) => {
        this.props.trackEvent([
            'Click',
            `CD:BrowseTheme-${theme.charAt(0).toUpperCase()}${theme.slice(1)}`,
        ]);

        const state = this.state;
        state.themes[theme] = !state.themes[theme];

        const activeThemes = Object.keys(_.omit(state.themes, active => !active));
        if (activeThemes.length) {
            this.decks.forEach(deck => {
                const isInTheme = deck.dataset.themes.match(/,/)
                    ? activeThemes.some(theme => deck.dataset.themes.split(',').includes(theme))
                    : activeThemes.some(theme => theme == deck.dataset.themes);

                deck.style.display = isInTheme ? 'block' : 'none';
            });
        } else {
            this.decks.forEach(deck => deck.style.display = 'block');
        }

        this.setState(state);
    }
}

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(null, mapDispatchToProps)(Component);
