import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { Suspense, lazy } from 'preact/compat';
const DeckSelect = lazy(
    () => import(/* webpackChunkName: "deck-select" */ '../../../tarot-reports/tarot-reading/deck-select')
);

class Component extends BaseComponent {
    componentDidMount() {
        if (!this.props.decks) {
            this.props.loadDecks();
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.deck != this.props.deck) {
            window.location.href = `/tarot/cards/${this.props.card}/${nextProps.deck.slug}`;

            return true;
        }

        if (nextProps.decks != this.props.decks) {
            return true;
        }

        return false;
    }

    render({ deck, card, message, decks }) {
        let event_info = 'CD:Cards-DeckDropdown';
        return (
            <deck-selector card={card} message={message}>
                {
                    deck && decks
                        ?
                        <Suspense fallback={<div className='skeleton' />}>
                            <DeckSelect
                                message={message}
                                deck={deck}
                                data-ga-event
                                data-info={event_info}
                            />
                        </Suspense>
                        :
                        <select
                            data-ga-event
                            data-category='Tarot Cards: Individual'
                            data-label='Card Dropdown Search'
                            data-info={event_info}
                        >
                            <option disabled selected>{message}</option>
                        </select>
                }
            </deck-selector>
        );
    }
}

const mapStateToProps = (state) => ({
    deck: state.deck,
    decks: state.decks,
});

const mapDispatchToProps = (dispatch) => ({
    loadDecks: () => dispatch({ type: 'decks:required' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
