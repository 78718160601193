/* global require */
import _ from 'underscore';

const card_deck_components = {
    'deck-selector': 'deck/deck-selector',
    'tarot-card-autocomplete': 'card/autocomplete',
    'tarot-card-selector': 'card/selector',
    'tarot-deck-autocomplete': 'deck/autocomplete',
    'tarot-deck-filters': 'deck/filters',
    'tarot-deck-selector': 'deck/selector',
};

_.each(card_deck_components, (path, tag) => {
    const { default: Component } = require(`./components/${path}`);

    import(/* webpackMode: "weak" */ '../app/index')
        .then((index) => {
            index.register(tag, Component);
        })
        .catch(() => {});
});