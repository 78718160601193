import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { Suspense, lazy } from 'preact/compat';
const AutoCompleteGroup = lazy(
    () => import(/* webpackChunkName: "autocomplete-group" */ '../../../shared/form/autocomplete-group')
);
import _ from 'underscore';

class Component extends BaseComponent {
    constructor() {
        super();
        this.options = {};
    }

    componentDidMount() {
        if (this.props.cards) {
            this.props.cards.map(card => this.options[card.canonical_rank] = this.getDisplayName(card));
        }
    }
    shouldComponentUpdate() {
        return false;
    }

    render({ placeholder, category, label, info }) {
        if (this.options) {
            return (
                <tarot-card-autocomplete category={category} label={label} info={info}>
                    <Suspense fallback={<div className='skeleton' />}>
                        <AutoCompleteGroup
                            placeholder={placeholder || 'Search Any Tarot Card Meaning'}
                            options={this.options}
                            onSelectItem={this.selectCard}
                            isHeight='auto'
                            type='tarot-bar'
                            hideErrors
                        />
                    </Suspense>
                </tarot-card-autocomplete>
            );
        }

        return null;
    }

    getDisplayName = (card) => {
        return card.canonical_suit_display ? `${card.name} (${card.canonical_suit_display})` : card.name;
    }

    selectCard = (e) => {
        if (Object.keys(this.options).includes(e.target.dataset.value)) {
            if (this.props.category && this.props.label) {
                this.props.trackEvent([
                    'Click',
                    `${this.props.info}_${e.target.dataset.value}`,
                ]);
            }
            const deck_slug = this.props.deck.slug == 'universal-waite' ? '' : this.props.deck.slug;
            document.location.href = `/tarot/cards/${e.target.dataset.value}/${deck_slug}`;
        }
    }
}

const mapStateToProps = (state) => ({
    cards: state.deck && state.deck.cards,
    deck: state.deck,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
