import { Component as BaseComponent, h } from 'preact';
import { connect } from 'react-redux';
import { Suspense, lazy } from 'preact/compat';
const AutoCompleteGroup = lazy(
    () => import(/* webpackChunkName: "autocomplete-group" */ '../../../shared/form/autocomplete-group')
);
import _ from 'underscore';

class Component extends BaseComponent {
    constructor() {
        super();
        this.options = {};
    }

    componentDidMount() {
        if (this.props.decks) {
            this.props.decks.map(deck => this.options[deck.slug] = deck.name);
        }
    }
    shouldComponentUpdate() {
        return false;
    }

    render({ category, label, info }) {
        if (this.options) {
            return (
                <tarot-deck-autocomplete category={category} label={label} info={info}>
                    <Suspense fallback={<div className='skeleton' />}>
                        <AutoCompleteGroup
                            placeholder='Search All Tarot Decks'
                            options={this.options}
                            onSelectItem={this.selectDeck}
                            isHeight='auto'
                            inputClass='is-background-color-white has-text-size-14 has-text-eminence'
                            type='tarot-bar'
                            hideErrors
                        />
                    </Suspense>
                </tarot-deck-autocomplete>
            );
        }

        return null;
    }

    selectDeck = (e) => {
        if (Object.keys(this.options).includes(e.target.dataset.value)) {
            if (this.props.category && this.props.label) {
                this.props.trackEvent([
                    'Click',
                    `${this.props.info}_${e.target.dataset.value}`,
                ]);
            }

            document.location.href = `/tarot/decks/${e.target.dataset.value}`;
        }
    }
}

const mapStateToProps = (state) => ({
    decks: state.decks,
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: (payload) => dispatch({ type: 'ga:event', payload: payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
